<template>
  <div class="card mb-0">
    <div v-if="showHeader" class="card-header">
      <h4 class="card-title">
        Search Result
      </h4>
      <div class="heading-elements">
        <ul class="list-inline mb-0">
          <li v-if="actions">
            <button
              type="button"
              class="btn btn-danger btn-block"
              @click="onClickHideSearchItemComponent()"
            >
              <i class="bi bi-arrow-left-square-fill" />
              Close
            </button>
          </li>
          <li v-else>
            <button
              type="button"
              class="btn btn-danger btn-block"
              @click="onClickHideSearchItemComponent()"
            >
              <i class="bi bi-arrow-left-square-fill" />
              Cancel
            </button>
          </li>
        </ul>
      </div>
    </div>
    <div class="card-body">
      <dx-data-grid
        id="searchMoveBatchItemGrid"
        ref="searchMoveBatchItemGridRef"
        :height="girdHeight"
        :data-source="searchMoveBatchItemDataSource"
        :allow-column-reordering="true"
        :allow-column-resizing="true"
        :column-auto-width="true"
        :show-column-lines="true"
        :show-row-lines="true"
        :show-borders="true"
        :row-alternation-enabled="false"
        :hover-state-enabled="true"
        :customize-columns="customizeColumns"
        @initialized="onInitialized"
        @content-ready="onContentReady"
      >
        <!--region    ::DataGird base components -->
        <dx-sorting mode="single" />
        <dx-paging :page-size="pagerOptions.pageSize" />
        <dx-pager
          :allowed-page-sizes="pagerOptions.allowedPageSizes"
          :show-info="pagerOptions.showInfo"
          :show-navigation-buttons="pagerOptions.showNavigationButtons"
          :show-page-size-selector="pagerOptions.showPageSizeSelector"
          :visible="pagerOptions.visible"
        />
        <!--endregion ::DataGird base components -->
        <dx-column
          data-field="title"
          caption="Item"
          alignment="left"
          cell-template="itemTemplate"
        />
        <template #itemTemplate="{ data }">
          <item-info :item-data="data.row.data" />
        </template>
        <dx-column :visible="actions" data-field="id" caption="Actions" alignment="center" cell-template="itemActions" :width="80" :fixed="true" fixed-position="left" />
        <template #itemActions="{ data }">
          <dx-util-button
            type="default"
            icon="plus"
            @click="onClickAddItem(data.row.data)"
          />
        </template>
      </dx-data-grid>
    </div>
  </div>
</template>
<script>
import GridBase from '@core/dev-extreme/mixins/grid/base'
import InventorySearchFilter from '@/http/models/search-filters/InventorySearchFilter'
import Pager from '@core/dev-extreme/utils/pager'
import useCurrentUser from '@/libs/app/current-user'
import inventoryService from '@/http/requests/inventory/inventoryService'
import { isLocation } from '@/libs/utils'
import ItemInfo from '../view/ItemInfo.vue'

export default {
  components: {
    ItemInfo,
  },
  mixins: [GridBase],
  props: {
    componentId: {
      type: String,
      default: '',
    },
    query: {
      type: String,
      default: '',
    },
    actions: {
      type: Boolean,
      default: false,
    },
    showHeader: {
      type: Boolean,
      default: true,
    },
    divider: {
      type: Number,
      default: 1.6,
    },
  },
  setup() {
    const { userWarehouseId } = useCurrentUser()
    return {
      userWarehouseId,
    }
  },
  data() {
    return {
      itemFilter: {
        location: '',
        query: '',
        itemAction: 'ALL',
      },
      searchMoveBatchItemDataSource: [],
    }
  },
  computed: {
    itemsDataGrid() {
      const dataGridIns = this.$refs.searchMoveBatchItemGridRef.instance
      return dataGridIns
    },
    itemsDataGridDataSource() {
      return this.itemsDataGridDataGrid.getDataSource()
    },
    girdHeight() {
      return window.innerHeight / this.divider
    },
  },
  watch: {
    componentId: {
      immediate: true,
      async handler(current) {
        if (current) {
          this.searchMoveBatchItemDataSource.length = 0
          await this.onEnterScanCode(this.query)
        }
      },
    },
  },
  methods: {
    onClickHideSearchItemComponent() {
      this.$emit('on-hide-search-item-component')
    },
    async onEnterScanCode(query) {
      const scanedCode = query
      this.itemFilter.location = ''
      this.itemFilter.query = ''
      if (isLocation(scanedCode)) {
        this.itemFilter.location = scanedCode
      } else {
        this.itemFilter.query = scanedCode
      }
      await this.searchItems()
    },
    async searchItems() {
      const pager = new Pager({})
      InventorySearchFilter.setDefaultFilters()
      InventorySearchFilter.warehouseId = this.userWarehouseId
      InventorySearchFilter.q = this.itemFilter.query
      InventorySearchFilter.itemAction = this.itemFilter.itemAction
      InventorySearchFilter.location = this.itemFilter.location

      const filters = InventorySearchFilter.getFilters()
      const response = await inventoryService.fetchByQuery(
        filters,
        pager.staticPageable,
      )
      const { data } = response
      this.searchMoveBatchItemDataSource = [...data.content]
    },
    onClickCloseSearchItemComponent() {

    },
    onClickAddItem(data) {
      this.$emit('on-hide-search-item-component', data)
    },
  },
}
</script>
