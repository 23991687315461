<template>
  <div class="container-fluid">
    <div class="row gutter-5 match-height">
      <div class="col-xl-2 col-lg-4 col-md-12 align-items-stretch">
        <div class="card mb-0">
          <div class="card-body">
            <div class="d-flex flex-column align-items-center justify-content-center mt-5">
              <i class="bi bi-box-seam-fill display-1 text-warning"></i>
              <dx-util-button
                type="normal"
                text="Create LPN"
                class="btn-warning mt-2"
                @click="onClickCreateLpn"
              />
            </div>
            <div id="preview" class="d-flex flex-column align-items-center justify-content-center mt-1">
              <div class="text-center">
                <svg id="barcodePreview" ref="barcodePreviewRef"></svg>
              </div>
              <div class="d-flex flex-row">
                <dx-util-button
                  type="default"
                  text="Downdload Barcode"
                  class="mt-1 mr-1"
                  :visible="isBarcodeCreated"
                  @click="onClickPrintBarcode"
                />
                <dx-util-button
                  type="success"
                  text="Set Location"
                  class="mt-1"
                  :visible="isBarcodeCreated"
                  @click="onClickSetLocation"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-xl-10 col-lg-8 col-md-12 align-items-stretch">
        <div class="card mb-0">
          <div class="card-body">
            <div class="row">
              <div class="col-xl-4 col-lg-6 col-md-12">
                <div v-if="setLocationContent" class="form-group row">
                  <div class="col-12 mb-1 h4 text-warning">
                    {{ lpn.lpn }}
                  </div>
                  <label for="source-location" class="col-12">Set Location</label>
                  <div class="input-group col-12 mb-1">
                    <div class="input-group-prepend">
                      <span id="source-location-addon" class="input-group-text bg-warning text-white">
                        <i class="bi bi-upc-scan"></i>
                      </span>
                    </div>
                    <input v-model="targetLocation" type="text" name="source-location" class="form-control" aria-describedby="help-source-location" @keyup.enter="onEventSetTargetLocation">
                  </div>
                  <div class="col-12">
                    <dx-util-button
                      type="success"
                      text="Save Location"
                      width="50%"
                      @click="onEventSetTargetLocation"
                    />
                    <dx-util-button
                      type="danger"
                      text="Later"
                      width="50%"
                      @click="onEventSetLaterTargetLocation"
                    />
                  </div>
                </div>
                <div v-else class="form-group row">
                  <label for="source-location" class="col-12">Search</label>
                  <div class="input-group col-12 mb-1">
                    <div class="input-group-prepend">
                      <span id="source-location-addon" class="input-group-text">
                        <i class="bi bi-search"></i>
                      </span>
                    </div>
                    <input v-model="query" type="text" name="source-location" class="form-control" aria-describedby="help-source-location" @keyup.enter="onClickSearchLpn">
                  </div>
                  <div class="col-12">
                    <dx-util-button
                      type="success"
                      text="Search LPN"
                      width="100%"
                      @click="onClickSearchLpn"
                    />
                  </div>
                </div>
              </div>
              <div v-if="!setLocationContent && lpns.length > 0" class="col-xl-8 col-lg-6 col-md-12">
                <table class="table table-bordered">
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>LPN</th>
                      <th>Location</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tr v-for="item, index in lpns" :key="item.id">
                    <td><span class="badge badge-warning">
                      {{ (index+1) }}
                    </span></td>
                    <td>{{ item.lpn }}</td>
                    <td class="w-100">
                      {{ item.locationName }}
                    </td>
                    <td class="d-block">
                      <div class="d-flex flex-row">
                        <div class="mr-half">
                      <dx-util-button
                        type="success"
                        icon="bulletlist"
                        hint="View Items"
                        @click="onListItems(item.lpn)"
                      />
                    </div>
                    <div class="mr-half">
                      <dx-util-button
                        type="default"
                        icon="download"
                        hint="Downdload Barcode"
                        @click="onClickDownloadBarcode(item.lpn)"
                      />
                    </div>
                    <div>
                      <dx-util-button
                        type="normal"
                        icon="map"
                        hint="Set Location"
                        class="btn-warning"
                        @click="onClickAssignLocation(item.lpn)"
                      />
                    </div>
                    </div>
                    </td>
                  </tr>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-if="searchItemComponentShow" class="col-12 px-0 align-items-stretch mt-1">
        <item-location :component-id="searchItemComponentId" :divider="2.4" :query="searchItemQuery" :actions="false" @on-hide-search-item-component="onEmitHideSearchItemComponent" />
      </div>
    </div>
  </div>
</template>
<script>
import { v4 as uuidv4 } from 'uuid'
import lpnService from '@/http/requests/warehouseLocation/location-lpn'
import useCurrentUser from '@/libs/app/current-user'
import ItemLocation from '../components/ItemLocation.vue'
// import PrintJS from 'print-js'

const JsBarcode = require('jsbarcode')

export default {
  components: {
    ItemLocation,
  },
  setup() {
    const { userWarehouseId } = useCurrentUser()
    return {
      userWarehouseId,
    }
  },
  data() {
    return {
      JsBarcode,
      lpns: [],
      lpn: {},
      query: '',
      isBarcodeCreated: false,
      barcodeOptions: {
        format: 'CODE128',
        width: 2,
        height: 150,
        displayValue: true,
        text: 'PrepShipHub',
        fontOptions: '',
        font: 'monospace',
        textAlign: 'center',
        textPosition: 'bottom',
        textMargin: 2,
        fontSize: 20,
        background: '#ffffff',
        lineColor: '#000000',
        margin: 10,
        marginTop: 10,
        marginBottom: 10,
        marginLeft: 10,
        marginRight: 10,
      },
      setLocationContent: false,
      targetLocation: '',
      searchItemComponentId: '',
      searchItemQuery: '',
      searchItemComponentShow: false,
    }
  },
  methods: {
    async onClickCreateLpn() {
      const data = {
        id: null,
        warehouseId: this.userWarehouseId,
        location: null,
      }
      const result = await lpnService.create(data)
      this.lpn = result.data.body

      const target = this.$refs.barcodePreviewRef
      target.classList.remove('d-none')
      const opt = { ...this.barcodeOptions }
      const barcodeText = this.lpn.lpn
      opt.text = this.lpn.lpn
      this.$nextTick(async () => {
        await this.JsBarcode(`#${target.id}`, barcodeText, opt)
        this.isBarcodeCreated = true
      })
    },
    onClickPrintBarcode() {
      const target = this.$refs.barcodePreviewRef
      const serializer = new XMLSerializer()
      const svgData = serializer.serializeToString(target)
      const canvas = document.createElement('canvas')
      const ctx = canvas.getContext('2d')
      const img = document.createElement('img')
      img.setAttribute('src', `data:image/svg+xml;base64,${btoa(svgData)}`)
      img.onload = () => {
        canvas.width = img.width
        canvas.height = img.height
        ctx.drawImage(img, 0, 0)
        const imgUrl = canvas
          .toDataURL('image/png')
          .replace('image/png', 'image/octet-stream')
        const a = document.createElement('a')
        a.href = imgUrl
        a.download = `${this.lpn.lpn}.png`
        a.click()
      }
    },
    onClickSetLocation() {
      this.setLocationContent = true
    },
    onClickAssignLocation(item) {
      this.lpn = { item }
      this.setLocationContent = true
    },
    async onEventSetTargetLocation() {
      const { id } = this.lpn
      if (id && this.targetLocation) {
        await lpnService.setLocation(id, this.targetLocation)
        this.setLocationContent = false
        this.targetLocation = ''
      }
    },
    onEventSetLaterTargetLocation() {
      this.setLocationContent = false
      this.targetLocation = ''
    },
    async onClickSearchLpn() {
      if (this.query) {
        this.lpns.length = 0
        const pattern = /^LPN/
        const isLpn = this.query.match(pattern)
        if (isLpn) {
          const result = await lpnService.fetchByLpn(this.query)
          this.lpns.push(result)
        } else {
          const result = await lpnService.fetchByLocation(this.query)
          this.lpns = [...result]
        }
      }
    },
    async onClickDownloadBarcode(lpn) {
      const target = this.$refs.barcodePreviewRef
      target.classList.add('d-none')
      const opt = { ...this.barcodeOptions }
      const barcodeText = lpn
      opt.text = lpn
      await this.JsBarcode(`#${target.id}`, barcodeText, opt)

      this.$nextTick(async () => {
        const serializer = new XMLSerializer()
        const svgData = serializer.serializeToString(target)
        const canvas = document.createElement('canvas')
        const ctx = canvas.getContext('2d')
        const img = document.createElement('img')
        img.setAttribute('src', `data:image/svg+xml;base64,${btoa(svgData)}`)
        img.onload = () => {
          canvas.width = img.width
          canvas.height = img.height
          ctx.drawImage(img, 0, 0)
          const imgUrl = canvas
            .toDataURL('image/png')
            .replace('image/png', 'image/octet-stream')
          const a = document.createElement('a')
          a.href = imgUrl
          a.download = `${lpn}.png`
          a.click()
        }
      })
    },
    onListItems(lpn) {
      if (lpn) {
        this.searchItemComponentShow = true
        this.searchItemComponentId = uuidv4()
        this.searchItemQuery = lpn
      }
    },
    onEmitHideSearchItemComponent() {
      this.searchItemComponentShow = false
      this.searchItemComponentId = ''
    },
  },
}
</script>
